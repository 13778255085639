import React, { Component } from 'react'

export default class Menu extends Component {

    data = this.props.Data;
    language = this.props.Language;

    handleClick = () => {

    }


  render() {
    return (
            <button
                className={`menuItem ${ this.props.selectedMenu? 'menuItemActive': ''}`}
                onClick={ () => this.props.handleMenuClick(this.props.Index)}>
                {
                    this.props.Language == "en" ? this.data['name_eng']
                    : this.props.Language == "krd" ? this.data['name_krd']
                    : this.data['name_ar']
                }
            </button>
    )
  }
}
