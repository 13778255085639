import React, { Component } from 'react';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';

class Subcategory extends Component {

    state = {
        collapse: true 
    }

    handleCollapse = () => {
        this.setState({collapse: !this.state.collapse});
    }

    render() {
        return (
            <div className="item" style={this.state.collapse ? {border: 'none', marginBottom: '-8px' }: {}}  >
                <div className="item-head" onClick={this.handleCollapse}>
                    {
                        this.props.Language == "en" ? this.props.Category.name_eng
                        : this.props.Language == "krd" ? this.props.Category.name_krd
                        : this.props.Category.name_ar
                    }

                    { this.state.collapse ? <i className="fa-solid fa-angle-down"></i>: <i className="fa-solid fa-angle-up"></i>}
                    
                </div>
                <div className="item-body" style={this.state.collapse ? {display: 'none' }: {}}>
                    {
                     this.props.ListView == "list" ? this.props.Items?.map(item => <List key={item.id}  Data = {item} Language = {this.props.Language} ></List> )
                    :  this.props.ListView == "two" ? <div className='style-two'> { this.props.Items?.map(item => <Two key={item.id}  Data = {item} Language = {this.props.Language} ></Two> ) } </div>
                    : <div className='style-large'> { this.props.Items?.map(item => <Large key={item.id}  Data = {item} Language = {this.props.Language} ></Large> ) } </div>
                    }
                </div>
            </div>
        );
    }
}

export default Subcategory;