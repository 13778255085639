import React, { Component } from 'react';
import imgLoading from '../../../assets/itemLoading.gif';
import { PhotoView } from 'react-photo-view';


class Imgloading extends Component {

    state = { 
        loading: true,
        onError: false,
     }

    img = this.props.Img;

    handleLoad = e => {
        this.setState({loading: false});
    }

    handleError = e => {
        this.setState({loading: false});
        this.setState({onError: true});
    }
    
    render() {
        return (
            <div className='image-loading'>
                { this.state.loading && <img src={imgLoading}></img> }
                    <PhotoView src={this.img}>
                        <img 
                            style={this.state.onError ? {display: 'none'} : {}}
                            src={this.img} 
                            onLoad={this.handleLoad} 
                            onError={this.handleError}
                            ></img>
                    </PhotoView>
                {this.state.onError && <img src={imgLoading}></img>}
            </div>
        );
    }
}

export default Imgloading;