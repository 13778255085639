import React, { Component } from 'react';
import Imgloading from './itemloading';

class List extends Component {

    data = this.props.Data;
    language = this.props.Language;

    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    addItemToCart = () => {
        this.props.addCartItem(this.data)
    }

    render() {
        return (
            <div className="style-list">
            <div className="side-left">
                <div className='style-two-info-footer'>
                    <div>

                        <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                            {/* {this.data.order??0} -  */}
                            {
                                this.props.Language == "en" ? this.data.name_eng
                                : this.props.Language == "krd" ? this.data.name_krd
                                : this.data.name_ar
                            } 
                        </h5>
                        <p> 
                            {
                                this.props.Language == "en" ? this.data.desc_eng
                                : this.props.Language == "krd" ? this.data.desc_krd
                                : this.data.desc_ar
                            } 
                        </p>
                        <div className='price'> 
                            {
                                (this.data.price_small && this.data.price_max)? 
                                    <>
                                        
                                        <span> 
                                            { this.priceText('small') } 
                                            {this.data.price_small} 
                                        </span>
                                        <span> 
                                            { this.priceText('medium') } 
                                            {this.data.price} 
                                        </span> 
                                        <span> 
                                            { this.priceText('big') }
                                            {this.data.price_max} 
                                        </span> 
                                    </>
                                :
                                (this.data.price_small)?
                                <>
                                    
                                    <span> 
                                        { this.priceText('small') }
                                        {this.data.price_small} 
                                    </span>

                                    <span> 
                                        { this.priceText('medium') } 
                                        {this.data.price} 
                                    </span> 

                                </>
                                :
                                (this.data.price_max)?
                                <>
                                    <span> 
                                        { this.priceText('medium') }  
                                        {this.data.price} 
                                    </span> 
                                    <span> 
                                        { this.priceText('big') }
                                        {this.data.price_max} 
                                    </span> 
                                </>
                                :
                                <span> {this.data.price}  </span> 

                            }
                        </div>
                    </div>
                    <div className='info-cart-add'>
                        <i className="fa-solid fa-plus"  onClick={()=> this.addItemToCart()}></i>
                    </div>
                </div>
            </div>
            <div className="side-right">
                <Imgloading Img={this.data.image} />
            </div>
        </div>
        );
    }
}

export default List;