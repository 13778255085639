import React, { Component } from 'react'

export default class Lang extends Component {

  state = {
    show: false,
  }

  handleChange = e => {
      this.props.handleLangaugeSubmit(e);
      this.setState({show: false});
  }

  render() {
    return (
      <div>
        <i className="fa-solid fa-globe" onClick={ () => this.setState({show: true}) }></i>

        <div className={`langModal ${this.state.show?'langModalShow':''}`}>
          <div className='langModalContainer'>

            <div className='langModalHead'>
              <i className="fa-solid fa-xmark" role='button' onClick={ () => this.setState({show: false}) }></i>
            </div>
              
            <div className='langModalBody'>
              <button type='submit' className='' onClick={() => this.handleChange('krd')} > کوردی </button>
              <button type='submit' className='' onClick={() => this.handleChange('ar')}> عربي </button>
              <button type='submit' className='' onClick={() => this.handleChange('en')}> English </button>
            </div>
          </div>
        </div>
        

      </div>
    )
  }
}
